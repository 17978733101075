<template>
    <div>
        <ts-page-title
            :title="$t('companyProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('companyProfile.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <a-button type="primary" @click="addNew">
                    {{ $t("addNew") }}
                </a-button>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>{{ record.company_name_kh }}</td>
                    <td>{{ record.company_name_en }}</td>
                    <td>{{ record.company_phone }}</td>
                    <td>{{ record.company_address }}</td>
                    <td>{{ record.company_website }}</td>
                    <td
                        class="tw-space-x-2 tw-whitespace-nowrap tw-flex tw-items-center"
                    >
                        <a
                            href="#"
                            v-tooltip="$t('companyProfile.otpMessage')"
                            class="text-info"
                            @click.prevent="setOtpMessage(record)"
                        >
                            <i class="fas fa-sms"></i>
                        </a>
                        <a
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('companyProfile.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
        >
            <from-action @cancel="clearEdit" />
        </a-modal>

        <!-- form-action -->
        <a-modal
            v-if="showOtpForm"
            v-model="showOtpForm"
            :title="$t('companyProfile.otpMessage')"
            centered
            :maskClosable="false"
            @cancel="clearSelect"
            :zIndex="1020"
            :footer="null"
        >
            <otp-message :selectedCompany="selectedCompany" @cancel="clearSelect" />
        </a-modal>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";
import OtpMessage from "./otp-message.vue";

export default {
    name: "companyIndex",
    components: {
        FromAction,
        OtpMessage
    },
    data() {
        return {
            loading: false,
            showForm: false,
            showOtpForm: false,
            selectedCompany: undefined
        };
    },
    computed: {
        ...mapState("corporate/companyProfile", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.corporate.companyProfile.search;
            },
            set(newValue) {
                this.$store.commit(
                    "corporate/companyProfile/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "corporate/companyProfile/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                { name: this.$t("companyProfile.nameKH") },
                { name: this.$t("companyProfile.nameEN") },
                { name: this.$t("companyProfile.phone") },
                { name: this.$t("companyProfile.address") },
                { name: this.$t("companyProfile.website") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("corporate/companyProfile/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        edit(record) {
            this.$store.commit(
                "corporate/companyProfile/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("corporate/companyProfile/SET_EDIT_DATA", {});
            this.showForm = false;
            this.fetchData();
        },
        setOtpMessage(record) {
            this.showOtpForm = true;
            this.selectedCompany = record;
        },
        clearSelect() {
            this.selectedCompany = undefined;
						this.showOtpForm = false
						this.fetchData()
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("corporate/companyProfile/RESET_STATE");
        next();
    }
};
</script>

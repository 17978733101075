<template>
    <div>
        <ts-loading-banner :loading="waiting">
            <div class="tw-flex tw-flex-col tw-space-y-2">
                <label class="required">{{
                    $t("companyProfile.content")
                }}</label>
								<div class="tw-bg-yellow-50 tw-text-yellow-500 tw-px-3 tw-py-2 tw-rounded-md">Please place the param <span class="tw-text-red-500">{code}</span> at any place you want.</div>
								<a-textarea
									v-model="model.otp_message"
									:auto-size="{ minRows: 3, maxRows: 30 }"
									placeholder="Your code is: {code} "
								/>
                <div class="invalid-feedback" v-if="errors.has('otp_message')">
                    {{ errors.first("otp_message") }}
                </div>
                <div class="row">
                    <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                        <ts-button
                            class="mr-2"
                            @click.prevent="() => $emit('cancel')"
                        >
                            {{ $t("cancel") }}
                        </ts-button>
                        <ts-button color="primary" @click.prevent="onSave">{{
                            $t("save")
                        }}</ts-button>
                    </div>
                </div>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "otpMessage",
    props: ["selectedCompany"],
    data() {
        return {
            waiting: false,
            errors: new Errors(),
            model: {
                otp_message: undefined
            }
        };
    },
		mounted(){
			this.model.otp_message = this.selectedCompany.otp_message
		},
    methods: {
        async onSave() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/companyProfile/setOTP", {
                    id: this.selectedCompany.company_id,
                    data: this.model
                })
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    }
};
</script>
